import request from "@/utils/request";

/**
 *  登录api
 */

// 登录
export const login = (data) => {
  return request({
    url: `/onecert/login/login`,
    method: "post",
    data,
  });
};
// 登录
export const loginIndex = (data) => {
  return request({
    url: `/onecert/login/index`,
    method: "post",
    data,
  });
};

// 登出
export const loginOut = (data) => {
  return request({
    url: `/onecert/login/logout`,
    method: "post",
    data,
  });
};

// 修改密码
export const changePwd = (data) => {
  return request({
    url: `/onecert/user/change/password?oldPwd=${data.oldPwd}&newPwd=${data.newPwd}`,
    method: "post",
    data,
  });
};

// 管理人员修改用户密码
export const changeUserPwd = (data) => {
  return request({
    url: `/onecert/user/repassword`,
    method: "post",
    data,
  });
};
