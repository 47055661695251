/**
 *  表单验证函数
 */
// 验证身份证
export function checkIdCard(rule, value, callback) {
  let _IDRe18 =
    /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

  if (!value) {
    callback(new Error("请输入身份证号码"));
  } else if (_IDRe18.test(value)) {
    callback();
  } else {
    callback(new Error("身份证号码格式不正确"));
  }
}

// 验证手机号码
export const checkPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入手机号"));
  } else if (!/^1[3-9]\d{9}$/.test(value)) {
    callback(new Error("手机号码格式不正确"));
  } else {
    callback();
  }
};

// 验证账号
export const checkAccount = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入用户名"));
  } else if (!/^[a-zA-Z0-9_-]{4,16}$/.test(value)) {
    callback(new Error("4到16位(a-z,A-Z,0-9,下划线,中横线)"));
  } else {
    callback();
  }
};

// 验证密码
export const checkPassWord = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入密码"));
  } else if (!/^[a-zA-Z0-9_-]{4,18}$/.test(value)) {
    callback(new Error("4到18位(a-z,A-Z,0-9,下划线,中横线)"));
  } else {
    callback();
  }
};

// 验证邮箱
export const checkEmail = (rule, value, callback) => {
  if (!value) {
    // callback(new Error("请输入邮箱"));
    callback();
  } else if (!/^\w+@[a-zA-Z0-9]{2,10}\.(com|cn)$/.test(value)) {
    callback(new Error("邮箱格式不正确"));
  } else {
    callback();
  }
};

// 验证站点编号
export const checkSiteCode = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入站点编号"));
    // callback();
  } else if (!/^[\d-]+$/.test(value)) {
    callback(new Error("站点编号格式不正确"));
  } else {
    callback();
  }
};
// 验证站域名
export const checkUrl = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入链接"));
  } else if (
    !/^((https|http|ftp|rtsp|mms){1}(:\/\/){0,1})(([A-Za-z0-9:/~#.?&=_-]+))$/.test(
      value
    )
  ) {
    callback(new Error("请输入以http或者https开头的完整地址"));
  } else {
    callback();
  }
};

// 验证纯数字
export const checkNum = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入数值"));
  } else if (!/^\d+$/.test(value)) {
    callback(new Error("只能输入数字"));
  } else {
    callback();
  }
};
