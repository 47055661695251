/**
 *  axios 工具函数层
 */
import axios from "axios";
import { message } from "@/utils/resetMessage";
// import local from "@/utils/local";
import router from "@/router";
import store from "@/store";
// 请求超时时间
axios.defaults.timeout = 30 * 1000;

// 设置统一服务器地址
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
// 请求拦截
axios.interceptors.request.use(
  (config) => {
    let token = store.getters.token;
    // let siteId = local.getLocal("siteId");
    if (token) {
      config.headers.Authorization = token;
      // config.headers.Authtoken = token
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    if (response.data.code !== undefined && response.data.msg !== undefined) {
      // 取出数据
      let { code, msg } = response.data;
      if (code === -1 || code === 9009) {
        message.error({
          message: msg,
        });
      } else if (code === 401 || code === 9001) {
        message.error({
          message: msg,
        });
        setTimeout(() => {
          store.commit("reset");
          router.push("/");
        }, 2000);
      }
    }
    return response;
  },
  (err) => {
    if (err.message.includes("timeout")) {
      message.error({
        message: "请求超时，请稍后再试",
      });
    }
    return Promise.reject(err);
  }
);

export default axios;
