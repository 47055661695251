<template>
  <div class="layouts flex flexc aic">
    <Header></Header>
    <div class="out-box flex1">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import { mapMutations, mapState } from "vuex";
// import local from "@/utils/local";
export default {
  components: {
    Header,
  },
  data() {
    return {
      portalUrl: process.env.VUE_APP_API_portal_URL,
      systemName: process.env.VUE_APP_sysName,
      breads: [],
    };
  },
  created() {
    // this.calcBreads();
  },
  // watch: {
  //   "$route.path"() {
  //     this.calcBreads();
  //   },
  // },
  computed: {
    ...mapState(["sysName"]),
  },
  methods: {
    ...mapMutations(["SET_deptList"]),
    // 计算面包屑
    calcBreads() {
      let temp = [
        { to: "/catalogue", title: "24小时自助证照打印中心管理后台" },
      ];
      //  过滤出路由数据
      let r = this.$route.matched
        .filter((v) => v.meta.title)
        .map((v) => {
          return { to: v.path, title: v.meta.title };
        });
      // 赋值给面包屑渲染
      this.breads = [...temp, ...r];
    },
  },
};
</script>

<style lang="less" scoped>
.layouts {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  .out-box {
    width: 98%;
    border-radius: 4px;
    background-color: #fff;
    // overflow-y: auto;
  }
}
</style>
