let customComponents = {
  install: function (Vue) {
    //在use的时候vue会自动调用此方法
    const files = require.context("@/components/autoRegister", true, /\.vue$/); //返回的是一个函数
    // require.context()的参数
    // 参数一 {String}：读取文件的目录路径
    // 参数二 {Boolean}：是否深入遍历，即是否遍历子目录（二级目录）
    // 参数三 {RegExp}：匹配目录内文件的正则表达式/\.vue$/表示匹配所有.vue后缀名的文件
    files.keys().forEach((item) => {
      const componentConfig = files(item);
      const name = componentConfig.default.name || componentConfig.name;
      const component = componentConfig.default || componentConfig;
      Vue.component(name, component); //注册当前组件
    });
  },
};
export default customComponents;
