<template>
  <el-date-picker
    :value="value"
    type="daterange"
    :value-format="valueFormat"
    unlink-panels
    :picker-options="pickerOptions"
    :clearable="Clearable"
    v-bind="$attrs"
    @input="handleChange"
    v-on="$listeners"
  >
  </el-date-picker>
</template>

<script>
export default {
  name: "YDatePicker",
  model: {
    event: "change",
    prop: "value",
  },

  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd",
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    Clearable() {
      return !!this.clearable;
    },
  },
  methods: {
    handleChange(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style lang="less" scoped></style>
