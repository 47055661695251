import CryptoJS from "crypto-js";
import ExportJsonExcel from "js-export-excel";

import Vue from "vue";

/**
 * call方法
 *
 * @param {Function} callMethod 调用的http包装方法
 * @param {String} url 调用接口
 * @param {Object} formData 需要发送参数
 * @param {Object} [config] 配置
 * @returns {Promise} axios请求
 */
const call = (callMethod, url, formData, config = {}) => {
  return new Promise((resolve, reject) => {
    callMethod(url, formData, config)
      .then((res) => {
        const { code, msg, data } = res;
        console.log(data);
        if (code !== 1) {
          reject({
            message: msg || "",
          });
          return;
        }
        resolve(res);
      })
      .catch((error) => {
        if (error.status === 403) {
          window.location.href = "/#/login";
        }
        reject(error);
      });
  });
};

/**
 * 普通的ajax post请求
 *
 * @param {Object} formData  需要发送参数
 * @param {String} url 调用接口
 * @param {Object} [config] 配置
 * @returns 科里化后的call方法
 */
export const normalCallPost = (url, formData, config = {}) => {
  let post=''
  return call(post, url, formData, config);
};


// 递归获取底层子数组
export function findBottomSubarrays(arr) {
  let bottomSubarrays = [];
  function recursiveSearch(subArr) {
    for (let item of subArr) {
      if (item.children && item.children.length) {
        recursiveSearch(item.children);
      } else {
        bottomSubarrays.push(...subArr);
        break;
      }
    }
  }
  recursiveSearch(arr);
  return bottomSubarrays;
}

// 加密数据
export let encrypt = (str, keyStr, ivStr) => {
  keyStr = keyStr ? keyStr : "0000000671595991";
  ivStr = ivStr ? ivStr : "tdrdadq59tbss5n7";
  //密钥16位
  let key = CryptoJS.enc.Utf8.parse(keyStr);
  //加密向量16位
  let iv = CryptoJS.enc.Utf8.parse(ivStr);
  let encrypted = CryptoJS.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};
// 解密
export const decrypt = (word, keyStr, ivStr) => {
  keyStr = keyStr ? keyStr : "0000000671595991";
  ivStr = ivStr ? ivStr : "tdrdadq59tbss5n7";
  let key = CryptoJS.enc.Utf8.parse(keyStr);
  let iv = CryptoJS.enc.Utf8.parse(ivStr);

  let decrypt = CryptoJS.AES.decrypt(word, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
};

/**
 * 加密存储临时数据并解析对象
 */
const aseKey = "**_FXxx_1234_KEY";
const KEY = "KEY_EXTRA";
export class SessionCrypto {
  // 加密
  static setItem(key = KEY, value = "") {
    if (typeof key === "string") {
      const stringify = JSON.stringify(value);
      const encrypt = CryptoJS.AES.encrypt(
        stringify,
        CryptoJS.enc.Utf8.parse(aseKey),
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      window.sessionStorage.setItem(key, encrypt);
      return encrypt;
    }
  }
  // 解密
  static getItem(key = KEY) {
    const ssStr = window.sessionStorage.getItem(key) || "";
    try {
      if (ssStr) {
        const decrypt = CryptoJS.AES.decrypt(
          ssStr,
          CryptoJS.enc.Utf8.parse(aseKey),
          {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
          }
        ).toString(CryptoJS.enc.Utf8);
        const parseStr = JSON.parse(decrypt);
        return parseStr;
      }
      return "";
    } catch (e) {
      return "";
    }
  }
  // 删除
  static remove(key) {
    window.sessionStorage.removeItem(key);
  }
}

// 数据切片
export const dataSection = async (fn, searchForm = {}, callback) => {
  let dataList = [];
  let page = 1;
  let size = 1000;
  let execute = async () => {
    let { data, total } = await fn({ page, size, ...searchForm });
    dataList = [...dataList, ...data];
    Vue.prototype.$app.loading = true;
    Vue.prototype.$app.percentage = parseInt((dataList.length / total) * 100);
    if (dataList.length >= total || data.length == 0) {
      if (callback) callback(dataList);
      Vue.prototype.$app.loading = false;
      Vue.prototype.$app.percentage = 1;
      return;
    }
    setTimeout(() => {
      page += 1;
      execute();
    });
  };
  execute();
};

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 表格清除选中
export function clearSelection(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].clearSelection();
  }
}

/**
  * 导出excel
  * @param {导出的表头名信息} tHeader 
  * @param {导出的表头字段名，需要导出表格字段名} filterVal 
  * @param {导出数据} list 
  * @param {导出文件名称} fileName 
       
*/

export function export2Excel(tHeader, filterVal, list, fileName) {
  let option = {
    fileName,
    datas: [
      {
        sheetData: list,
        sheetName: "sheet",
        sheetFilter: filterVal,
        sheetHeader: tHeader,
        // columnWidths: columnWidths, // 列宽
      },
    ],
  };
  let toExcel = new ExportJsonExcel(option);
  toExcel.saveExcel(); //保存
}

export function uniqueFunc(arr, uniId) {
  const res = new Map();
  return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
}

// 构建树形数据
export function buildTree(data, idField = "id", parentIdField = "parentId") {
  const idToItem = {};
  data.forEach((item) => {
    idToItem[item[idField]] = { ...item, childList: [] };
  });

  const tree = [];
  data.forEach((item) => {
    const parentId = item[parentIdField];
    if (parentId === null || parentId === 0) {
      tree.push(idToItem[item[idField]]);
    } else {
      const parent = idToItem[parentId];
      if (parent) {
        parent.childList.push(idToItem[item[idField]]);
      } else {
        tree.push(idToItem[item[idField]]);
      }
    }
  });

  return tree;
}

// 递归从数组里边取出某一字段
export const getFieldFromArray = (arr, field, treeField) => {
  let result = [];
  arr.forEach((item) => {
    if (item[field]) {
      result.push(item[field]);
    }

    // 如果有子路由，递归提取子路由的路径
    if (item[treeField] && item[treeField].length > 0) {
      result = result.concat(
        getFieldFromArray(item[treeField], field, treeField)
      );
    }
  });

  return result;
};
