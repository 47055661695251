import store from "@/store/index";
export const permission = {
  inserted: function (el, binding) {
    const { value } = binding;
    const roles = store.getters["userInfo"].id;
    if (value) {
      const permissionRoles = value;
      const hasPermission = permissionRoles.includes(roles);
      if (!hasPermission) {
        el.remove();
      }
    } else {
      throw new Error(`need roles! Like v-permission="['admin','editor']"`);
    }
  },
};

export const permission2 = {
  inserted: function (el, binding) {
    const { value } = binding;
    const roles = store.getters["userInfo"].id;
    const { roleId, startTime, endTime } = value;
    if (
      roleId &&
      startTime != undefined &&
      startTime != null &&
      endTime != undefined &&
      endTime != null
    ) {
      const permissionRoles = roleId;
      const hasPermission = permissionRoles.includes(roles);
      const isForbid = estimateCurTime(startTime, endTime);
      if (hasPermission || !isForbid) {
        return;
      } else {
        el.remove();
      }
    } else {
      throw new Error(
        `need roles! Like v-permission="{ roleId: [1],startTime: 0, endTime: 17,}"`
      );
    }
  },
};

// 判断当前时间
function estimateCurTime(startTime, endTime) {
  const currentTime = new Date();
  // 获取当前小时
  const currentHour = currentTime.getHours();
  return currentHour >= startTime && currentHour < endTime;
}
