//防止element点击多次弹出message弹框
//创建一个js文件将此内容放入即可

import { Message } from "element-ui";

let messageval = null;

const Messages = (opt) => {
  if (messageval) {
    // 如果已经有一个message

    messageval.close(); // 将上一个关闭
  }

  messageval = Message(opt);
};

["error", "success", "info", "warning"].forEach((type) => {
  // 判断弹窗类型

  Messages[type] = (opt) => {
    if (typeof opt === "string") {
      opt = {
        message: opt,
        duration: 3000,
      };
    }

    opt.type = type;

    return Messages(opt);
  };
});

export const message = Messages;
