import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";
// var ls = new SecureLS({ isCompression: false });
import { SessionCrypto } from "@/utils";
import VuexReset from "@ianwalter/vuex-reset";
import { normalCallPost } from "@/utils";

Vue.use(Vuex);
function convertTreeToList(root, array) {
  if (!root || !root.length) return array;
  root.forEach((item) => {
    let data = JSON.parse(JSON.stringify(item));
    const url = data.url
      ? data.url
      : item.childList[0]
      ? item.childList[0].url || ""
      : "";
      console.log(url);
    delete data.childList;
    array.push({
      name: data.name,
      path: data.url,
      id: data.id,
      parentId: data.parentId,
      icon: data.imgPath,
    });
    if (item.childList) {
      convertTreeToList(item.childList, array);
    }
  });
  return array;
}
export default new Vuex.Store({
  state: {
    menus: [], // 菜单
    classifyList: [], // 证照分类列表数据
    industryList: [], // 行业列表数据
    catalogList: [], // 证照目录列表数据
    userInfo: {}, // 用户信息
    token: "",
    sysName: "", // 系统名称
    sysLogo: "", // 系统logo
    path: "/", // 门户过来的路由
  },
  getters: {
    classifyList(state) {
      return state.classifyList;
    },
    industryList(state) {
      return state.industryList;
    },
    catalogList(state) {
      return state.catalogList;
    },
    userInfo(state) {
      return state.userInfo;
    },
    token(state) {
      return state.token;
    },
  },
  mutations: {
    SET_path(state, path) {
      state.path = path;
    },
    SET_sysName(state, sysName) {
      state.sysName = sysName;
    },
    SET_sysLogo(state, sysLogo) {
      state.sysLogo = sysLogo;
    },
    SET_MENUS(state, menus) {
      state.menus = menus;
    },
    SET_classifyList(state, classifyList) {
      state.classifyList = classifyList;
    },
    SET_IndustryList(state, industryList) {
      state.industryList = industryList;
    },
    SET_catalogList(state, catalogList) {
      state.catalogList = catalogList;
    },
    SET_userInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_token(state, token) {
      state.token = token;
    },
    setUserData(state, data) {
      data.flat = convertTreeToList(data.barList, []);
      state.userData = {};
      state.userData = Object.assign({}, state.userData, data);
      state.isLogin = true;
      window.sessionStorage.userData = JSON.stringify(data);
      window.sessionStorage.token = data.token;
    },
    // 头部导航栏数据
    setHeadBar(state, data) {
      state.headBar = data;
      window.sessionStorage.headBar = JSON.stringify(data);
    },
    logout(state) {
      state.userData = {};
      state.isLogin = false;
      // window.sessionStorage.userData = '';
      // window.sessionStorage.token = '';
      // window.sessionStorage.headBar = ''
      // window.sessionStorage.currentParentName = ''
      // window.sessionStorage.CurrentSecondPath = ''
      // window.sessionStorage.CurrentThirdArr = ''
      window.sessionStorage.clear();
    },
    setGroup(state, data) {
      state.group = data;
    },
    // 当前以及导航名
    setCurrentParentName(state, data) {
      state.currentParentName = data;
      window.sessionStorage.currentParentName = data;
    },
    setCurrentSecondPath(state, data) {
      state.CurrentSecondPath = data;
      window.sessionStorage.CurrentSecondPath = data;
    },
    // 当前三级导航栏
    setCurrentThirdArr(state, data) {
      state.CurrentThirdArr = data;
      window.sessionStorage.CurrentThirdArr = JSON.stringify(data);
    },
    // 当前三级导航栏路径
    setThirdPath(state, data) {
      state.ThirdPath = data;
      window.sessionStorage.ThirdPath = data;
    },
    // 重置所有仓库状态
    reset: () => {},
  },
  actions: {
    login({ commit }) {
      normalCallPost("/login/index")
        .then(({ data }) => {
          commit("setUserData", data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout({ commit }) {
      normalCallPost("/login/logout")
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          commit("logout");
        });
    },
  },
  modules: {},
  plugins: [
    // 重置状态插件
    VuexReset(),
    createPersistedState({
      key: "info",
      storage: {
        getItem: (key) => SessionCrypto.getItem(key),
        setItem: (key, value) => SessionCrypto.setItem(key, value),
        removeItem: (key) => SessionCrypto.remove(key),
      },
    }),
  ],
});
