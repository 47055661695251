import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import FormField from "@/components/FormField";
Vue.component("Field", FormField);

// 引入清除样式,重置样式,公共样式
import "@/assets/css/normalize.css";
import "@/assets/css/reset.css";
import "@/assets/css/common.less";
import "@/assets/css/tailwindcss.css";

// moment 时间处理
import moment from "moment";
Vue.prototype.$moment = moment;
// 注册全局组件
import customComponents from "@/components";
Vue.use(customComponents);

// 引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import { message } from "@/utils/resetMessage";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
Vue.component("ElImageViewer", ElImageViewer);
Vue.prototype.$message = message;
// 表格生成
import plugins from "./components/formDes/index";
Vue.use(plugins);
import FcDesigner from "@form-create/designer";
Vue.use(FcDesigner);
Vue.use(FcDesigner.formCreate);

import { dataSection, resetForm, export2Excel, clearSelection } from "@/utils";
Vue.prototype.$dataSection = dataSection;
Vue.prototype.$resetForm = resetForm;
Vue.prototype.$export2Excel = export2Excel;
Vue.prototype.$resetForm = resetForm;
Vue.prototype.$clearSelection = clearSelection;

// 引入注册全局指令
import * as directives from "@/directive";
Object.keys(directives).forEach((name) =>
  Vue.directive(name, directives[name])
);

// 中央事件
Vue.prototype.$bus = new Vue();
// 深拷贝
import { cloneDeep } from "lodash-es";
Vue.prototype.$cloneDeep = cloneDeep;
// 全局过滤器
import * as filters from "@/filters";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
