import store from "@/store";
// 过滤证照生成类型
export const filterCreateLicenseType = (val) => {
  const obj = {
    0: "未生成",
    1: "正本",
    2: "副本",
    3: "正本、副本",
  };
  let str;
  Object.keys(obj).forEach((key) => {
    if (key == val) {
      str = obj[key];
    }
  });
  return str;
};
// 过滤证照持有者类型
export const filterHolderType = (val) => {
  const obj = {
    1: "自然人",
    2: "法人",
    3: "自然人，法人",
  };

  let str;

  Object.keys(obj).forEach((key) => {
    if (key == val) {
      str = obj[key];
    }
  });
  return str;
};
// 过滤证照生成状态
export const filterGenerateStatus = (val) => {
  const obj = {
    0: "未生成",
    1: "正本",
    2: "副本",
    3: "正副本",
  };
  let str;
  Object.keys(obj).forEach((key) => {
    if (key == val) {
      str = obj[key];
    }
  });
  return str;
};
// 过滤证照分类
export const filterClassify = (val) => {
  let str = store.getters.classifyList
    .filter((v) => v.id === val)
    .map((v) => v.classifyName)
    .join(",");
  return str;
};
// 过滤行业
export const filterIndustry = (val) => {
  let str = store.getters.industryList
    .filter((v) => v.id === val)
    .map((v) => v.industryName)
    .join(",");
  return str;
};
