<template>
  <el-dialog title="修改密码" :visible.sync="Visible" width="30%">
    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
      <!-- <el-form-item label="账号" prop="loginName">
        <el-input v-model="form.loginName" placeholder="请输入账号"></el-input>
      </el-form-item> -->
      <el-form-item label="原密码" prop="oldPwd">
        <el-input
          clearable
          show-password
          v-model="form.oldPwd"
          placeholder="请输入原密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPwd">
        <el-input
          clearable
          show-password
          v-model="form.newPwd"
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="newPwdAgain">
        <el-input
          clearable
          show-password
          v-model="form.newPwdAgain"
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { checkPassWord } from "@/utils/validate";
import { changePwd } from "@/api/login";
import { mapMutations } from "vuex";
export default {
  props: {
    changePwdVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const changePwdAgain = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPwd) {
        callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    };
    return {
      form: {
        // loginName: "",
        oldPwd: "",
        newPwd: "",
        newPwdAgain: "",
      },
      rules: {
        // loginName: [
        //   { required: true, validator: checkAccount, trigger: "blur" },
        // ],
        oldPwd: [{ required: true, validator: checkPassWord, trigger: "blur" }],
        newPwd: [{ required: true, validator: checkPassWord, trigger: "blur" }],
        newPwdAgain: [
          { required: true, validator: changePwdAgain, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    Visible: {
      get() {
        return this.changePwdVisible;
      },
      set(val) {
        this.$emit("update:changePwdVisible", val);
      },
    },
  },
  methods: {
    ...mapMutations(["reset"]),
    // 关闭对话框
    handleClose() {
      this.$resetForm("form");
      this.Visible = false;
    },
    // 确定
    handleOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await changePwd(this.form);
          let { code } = res.data;
          if (code === 1) {
            this.$message.success("密码修改成功，请重新登录");
            this.Visible = false;
            setTimeout(() => {
              this.reset();
              this.$router.push("/");
            }, 1000);
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
