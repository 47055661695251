import Vue from "vue";
import VueRouter from "vue-router";
import Layouts from "@/pages/layouts/Layouts.vue";
import store from "@/store";
// import local from "@/utils/local";
// 解决重复点击同一个路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
/**
 *  Route 对象配置:
 * hidden：控制路由是否显示在在菜单
 * hideChildrenInMenu:强制菜单显示为Item而不是SubItem
 * meta对象配置:
 * title:标题
 * keepAlive:缓存该路由
 * activeMenu:当前的激活路由
 * icon:图标
 * hidden：控制路由是否显示在在菜单
 */
const routes = [
  {
    path: "/",
    component: Layouts,
    redirect: "/login",
    children: [
      {
        path: "/catalogue",
        component: () => import("@/pages/software/catalogue/Catalogue"),
        meta: {
          icon: "el-icon-notebook-2",
          title: "证照目录",
        },
        hideChildrenInMenu: true,
        redirect: "/catalogue/Cataloguemanage",
        children: [
          {
            path: "/catalogue/Cataloguemanage",
            component: () =>
              import(
                "@/pages/software/catalogue/cataloguemanage/CatalogueManage.vue"
              ),
            meta: {
              icon: "el-icon-notebook-1",
              title: "证照目录管理",
              activeMenu: "/catalogue",
            },
          },
          {
            path: "/catalogue/Cataloguemaintain",
            component: () =>
              import(
                "@/pages/software/catalogue/cataloguemaintain/CatalogueMaintain.vue"
              ),
            meta: {
              title: "目录维护",
              icon: "el-icon-set-up",
              activeMenu: "/catalogue",
            },
          },
          // {
          //   path: "/catalogue/Cataloguetemplate",
          //   component: () =>
          //     import(
          //       "@/pages/software/catalogue/cataloguetemplate/CatalogueTemplate.vue"
          //     ),
          //   meta: {
          //     title: "证照模板",
          //     icon: "el-icon-bank-card",
          //     activeMenu: "/catalogue",
          //   },
          // },
          // {
          //   path: "/catalogue/PrintServemanage",
          //   component: () =>
          //     import(
          //       "@/pages/software/catalogue/printservemanage/PrintServeManage.vue"
          //     ),
          //   meta: {
          //     title: "打印服务",
          //     icon: "el-icon-bangzhu",
          //     activeMenu: "/catalogue",
          //   },
          // },
        ],
      },
      {
        path: "/printset",
        component: () => import("@/pages/software/printSet/PrintSet"),
        meta: {
          icon: "el-icon-printer",
          title: "打印设置",
        },
      },
      {
        path: "/connection",
        component: () => import("@/pages/connection/Connection.vue"),
        hideChildrenInMenu: true,
        redirect: "/connection/awaitConnection",
        meta: {
          icon: "el-icon-document-copy",
          title: "子证联报",
        },
        children: [
          {
            path: "/connection/awaitConnection",
            component: () =>
              import("@/pages/connection/awaitConnection/AwaitConnection.vue"),
            meta: {
              title: "待联报",
              icon: "el-icon-takeaway-box",
              activeMenu: "/connection",
            },
          },
          {
            path: "/connection/historyConnection",
            component: () =>
              import(
                "@/pages/connection/historyConnection/HistoryConnection.vue"
              ),
            meta: {
              title: "历史联报",
              icon: "el-icon-tickets",
              activeMenu: "/connection",
            },
          },
        ],
      },
      {
        path: "/licenseData",
        component: () => import("@/pages/licenseData/LicenseData.vue"),
        hideChildrenInMenu: true,
        redirect: "/licenseData/licenseapply",
        meta: {
          icon: "el-icon-date",
          title: "证照数据",
        },
        children: [
          {
            path: "/licenseData/licenseapply",
            component: () =>
              import("@/pages/licenseData/licenseApply/LicenseApply.vue"),
            meta: {
              title: "证照申请",
              icon: "el-icon-document-copy",
              activeMenu: "/licenseData",
            },
          },
          {
            path: "/licenseData/licensehold",
            component: () =>
              import("@/pages/licenseData/licenseHold/LicenseHold.vue"),
            meta: {
              title: "证照持有",
              icon: "el-icon-reading",
              activeMenu: "/licenseData",
            },
          },
        ],
      },
      {
        path: "/devicemanage",
        component: () => import("@/pages/hardware/DeviceManage"),
        meta: {
          icon: "el-icon-receiving",
          title: "设备管理",
        },
      },

      {
        path: "/printrecord",
        component: () =>
          import("@/pages/reportHistory/printRecord/PrintRecord"),
        meta: {
          icon: "el-icon-date",
          title: "打印记录",
        },
      },
      {
        path: "/system",
        component: () => import("@/pages/system/System.vue"),
        hideChildrenInMenu: true,
        meta: {
          icon: "el-icon-setting",
          title: "系统设置",
        },
        redirect: "/system/user",
        children: [
          {
            path: "/system/user",
            component: () => import("@/pages/system/user/User"),
            meta: {
              title: "用户管理",
              icon: "el-icon-user",
              activeMenu: "/system",
            },
          },
          {
            path: "/system/role",
            component: () => import("@/pages/system/role/Role"),
            meta: {
              title: "角色管理",
              icon: "el-icon-bank-card",
              activeMenu: "/system",
            },
          },
          {
            path: "/system/department",
            component: () => import("@/pages/system/department/Department"),
            meta: {
              title: "部门管理",
              icon: "el-icon-set-up",
              activeMenu: "/system",
            },
          },
          {
            path: "/system/childlicense",
            component: () =>
              import("@/pages/system/childLicense/ChildLicense.vue"),
            meta: {
              title: "子证配置",
              icon: "el-icon-document-copy",
              activeMenu: "/system",
            },
          },
          {
            path: "/system/resourcemanage",
            component: () =>
              import("@/pages/system/resourceManage/ResourceManage.vue"),
            meta: {
              activeMenu: "/system",
              title: "资源信息",
              icon: "el-icon-box",
            },
          },
          {
            path: "/system/menu",
            component: () => import("@/pages/system/menu/Menu.vue"),
            meta: {
              activeMenu: "/system",
              title: "菜单管理",
              icon: "el-icon-guide",
            },
          },
          {
            path: "/system/parameter",
            component: () => import("@/pages/system/parameter/Parameter.vue"),
            meta: {
              activeMenu: "/system",
              title: "系统参数",
              icon: "el-icon-cpu",
            },
          },
          {
            path: "/system/task",
            component: () => import("@/pages/system/task/TaskSet.vue"),
            meta: {
              activeMenu: "/system",
              title: "任务信息",
              icon: "el-icon-bank-card",
            },
          },
          {
            path: "/system/logs",
            component: () => import("@/pages/system/logs/Logs.vue"),
            meta: {
              activeMenu: "/system",
              title: "系统日志",
              icon: "el-icon-notebook-1",
            },
          },
        ],
      },
    ],
  },

  {
    path: "/login",
    hidden: true,
    component: () => import("@/pages/login/Login"),
  },
];

const router = new VueRouter({
  routes,
});

// 前置路由卫士
router.beforeEach((to, from, next) => {
  let islogin = store.getters.token;
  if (islogin) {
    next();
  } else {
    // 再次判断防止死循环
    if (to.path === "/login") {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});

// 过滤菜单
function menusFilter(arr) {
  let router = arr.filter((v) => {
    if (!v.hidden) {
      if (v.children) {
        v.children = menusFilter(v.children);
      }
      return v;
    }
  });
  return router;
}
// 动态菜单
export function calcMenu() {
  // dynamicRouter.forEach((v) => {
  //   router.addRoute(v);
  // });
  let menus = menusFilter(routes[0].children);
  store.commit("SET_MENUS", menus);
}
calcMenu();

export default router;
