// 表单节点封装，支持 input textarea select radio checkbox 及slot方式
<template>
  <el-col :span="span">
    <el-form-item :label="label" :prop="prop" class="my-form-field">
      <slot>
        <el-input
          show-word-limit
          :maxlength="maxLength"
          :disabled="disabled"
          :placeholder="placeholder"
          v-model="field"
          @change="emit"
          @input="emit"
          v-if="type === 'text'"
          :class="inputClass"
          :clearable="clearable"
        ></el-input>
        <el-input
          show-word-limit
          :maxlength="maxLength"
          :disabled="disabled"
          :placeholder="placeholder"
          v-model="field"
          @change="emit"
          @input="emit"
          type="password"
          v-if="type === 'password'"
        ></el-input>

        <el-input-number
          :disabled="disabled"
          v-model="field"
          size="small"
          :placeholder="placeholder"
          @change="emit"
          @input="emit"
          v-if="type === 'num'"
        ></el-input-number>

        <el-input
          show-word-limit
          :disabled="disabled"
          :placeholder="placeholder"
          v-model="field"
          :maxlength="maxLength"
          @change="emit"
          @input="emit"
          v-if="type === 'textarea'"
          type="textarea"
          :autosize="textareaSize"
          :class="inputClass"
        ></el-input>

        <!-- 一级 -->
        <el-select
          :disabled="disabled"
          v-model="field"
          @change="emit"
          :multiple="multiple"
          :filterable="filterable"
          :clearable="clearable"
          v-if="type === 'select'"
          style="width: 100%"
        >
          <el-option
            v-for="($label, $value) in enumData"
            :key="$value"
            :label="$label"
            :value="Number($value)"
          ></el-option>
        </el-select>

        <!-- 多级 -->
        <el-cascader
          :disabled="disabled"
          v-model="field"
          @change="emit"
          :options="enumData"
          :clearable="clearable"
          :props="{ label: 'label', value: 'id', multiple: multiple }"
          v-if="type === 'cascader'"
        >
        </el-cascader>

        <!-- 联级选择 -->
        <el-cascader-panel
          :disabled="disabled"
          v-model="field"
          @change="emit"
          :options="enumData"
          :clearable="clearable"
          :props="{ label: 'label', value: 'id', multiple: multiple }"
          v-if="type === 'cascaderpanel'"
        ></el-cascader-panel>

        <el-radio-group
          :disabled="disabled"
          v-model="field"
          @change="emit"
          v-if="type === 'radio'"
        >
          <el-radio
            v-for="($label, $value) in enumData"
            :key="$value"
            :label="$value"
            style="margin-bottom: 10px"
            >{{ $label }}</el-radio
          >
        </el-radio-group>
        <el-radio-group
          :disabled="disabled"
          v-model="field"
          @change="emit"
          v-if="type === 'radioCol'"
          style="display: flex; margin-top: 12px"
        >
          <el-radio
            v-for="($label, $value) in enumData"
            :key="$value"
            :label="$value"
            style="margin-bottom: 15px"
            >{{ $label }}</el-radio
          >
        </el-radio-group>

        <el-checkbox-group
          :disabled="disabled"
          v-model="field"
          @change="emit"
          v-if="type === 'checkbox'"
        >
          <el-checkbox
            v-for="($label, $value) in enumData"
            :key="$value"
            :label="$value"
            >{{ $label }}</el-checkbox
          >
        </el-checkbox-group>
        <div v-if="type === 'selectdate'" style="display: flex">
          <el-select
            :disabled="disabled"
            v-model="selectValue"
            placeholder="请选择"
            style="width: 45%"
            @change="emit"
          >
            <el-option label="非永久" :value="0"> </el-option>
            <el-option label="永久" :value="1"> </el-option>
          </el-select>
          <el-date-picker
            :disabled="disabled"
            type="date"
            value-format="yyyy-MM-dd"
            v-model="field"
            @change="emit"
            @input="emit"
            v-if="selectValue == 0"
            placeholder="选择日期"
          ></el-date-picker>
        </div>
        <el-date-picker
          :disabled="disabled"
          type="date"
          value-format="yyyy-MM-dd"
          v-model="field"
          @change="emit"
          @input="emit"
          placeholder="选择日期"
          style="width: 100%"
          v-if="type === 'date'"
        ></el-date-picker>
        <el-date-picker
          :disabled="disabled"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="field"
          @change="emit"
          @input="emit"
          placeholder="选择日期"
          v-if="type === 'datetime'"
          style="width: 100%"
        ></el-date-picker>
        <el-date-picker
          v-model="field"
          :disabled="disabled"
          type="daterange"
          @change="emit"
          @input="emit"
          value-format="yyyy-MM-dd"
          v-if="type == 'picker'"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </slot>
    </el-form-item>
  </el-col>
</template>

<script>
export default {
  props: {
    // 字段值
    value: {
      type: [String, Number, Array, Object],
    },
    // 组件的el-col span宽度 1-24,默认12
    span: {
      type: Number,
      default: 12,
    },
    // 字段类型: text,password,textarea,select,radio,checkbox,date,datetime
    type: {
      type: String,
      default: "text",
    },
    // 字段name
    prop: {
      type: String,
      default: "",
    },
    // 字段中文标题
    label: {
      type: String,
      default: "",
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否提示
    placeholder: {
      type: String,
      default: "",
    },
    // 字段所需的枚举类型
    enumData: {
      type: Object,
      default: () => {},
    },
    // textarea专用-自适应内容高度
    textareaSize: {
      type: Object,
      default: () => {},
    },
    // select专用-是否可搜索
    filterable: {
      type: Boolean,
      default: false,
    },
    // select专用-是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // select专用-是否可取消
    clearable: {
      type: Boolean,
      default: false,
    },
    // 字段所需的枚举类型
    options: {
      type: Array,
      default: () => [],
    },
    children: {
      type: Array,
      default: () => [],
    },
    inputClass: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
    },
  },
  methods: {
    emit() {
      this?.type == "selectdate"
        ? this.selectValue == 1
          ? (this.newVal = "")
          : ""
        : "";
      this.$emit("input", this.newVal);
      this.$emit("change", this.newVal);
      this.$emit("focus", this.newVal);
      this.$emit("cascaderChange", this.newVal);
    },
  },
  computed: {
    field: {
      get() {
        // if (Array.isArray(this.value)) {
        //   this.value = this.value.map((v) => {
        //     return typeof v == 'number' ? v + "" : v;
        //   });
        // }
        return this.value;
      },
      set(val) {
        this.newVal = val;
        this.$forceUpdate();
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          val = val.map((v) => {
            return typeof v == "number" ? v + "" : v;
          });
        }
        this.field = val;
        this.$forceUpdate();
      },
    },
    type: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val == "selectdate") {
          this.value.length > 1
            ? (this.selectValue = 0)
            : (this.selectValue = 1);
        }
      },
    },
  },
  data() {
    return {
      newVal: this.value,
      selectValue: 0,
    };
  },
};
</script>
<style lang="less" scoped>
/deep/.el-date-editor .el-range-separator {
  width: 8%;
}
/deep/.el-date-editor--daterange.el-input__inner {
  width: 100%;
}
</style>
