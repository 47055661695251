<template>
  <div class="header aic jcb flex">
    <div class="left aic flex">
      <img
        class="pointer"
        height="32"
        :src="sysLogo ? sysLogo : require('../../../assets/img/logo.png')"
        alt="LOGO"
        @click="handleGoHome"
      />
      <h1 class="title ml10 pointer" @click="handleGoHome">
        {{ sysName ? sysName : systemName }}
      </h1>
    </div>
    <!-- 导航 -->
    <el-menu
      :default-active="activeMenu"
      mode="horizontal"
      router
      text-color="rgba(254, 254, 254, 0.65)"
    >
      <template v-for="v in menus">
        <el-submenu
          v-if="!v.hideChildrenInMenu && v.children && v.children.length"
          :key="'a' + v.path"
          :index="v.path"
        >
          <template slot="title">
            <i v-if="v.meta && v.meta.icon" :class="v.meta.icon"></i>
            {{ v.meta.title }}
          </template>
          <el-menu-item
            v-for="item in v.children"
            :key="item.path"
            :index="item.path"
          >
            <i v-if="item.meta && item.meta.icon" :class="item.meta.icon"></i>
            {{ item.meta && item.meta.title }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :key="v.path" :index="v.path">
          <i v-if="v.meta && v.meta.icon" :class="v.meta.icon"></i>
          {{ v.meta.title }}
        </el-menu-item>
      </template>
    </el-menu>

    <div class="user-box">
      <el-dropdown @command="handleCheck">
        <span class="name cursor-pointer text-[#fff]">
          欢迎你，{{ userInfo.currUserName }}
          <i class="el-icon-arrow-down el-icon--right"></i
        ></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="editPwd">修改密码</el-dropdown-item>
          <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码弹窗 -->
    <ChangePwd :changePwdVisible.sync="changePwdVisible"></ChangePwd>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { loginOut } from "@/api/login";
import ChangePwd from "@/components/ChangePwd.vue";
export default {
  components: {
    ChangePwd,
  },
  data() {
    return {
      api: process.env.VUE_APP_API_LOGO_URL,
      systemName: process.env.VUE_APP_sysName,
      // portal: "http://112.19.80.237:11072",
      changePwdVisible: false,
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    ...mapGetters(["token", "userInfo"]),
    ...mapState(["menus", "sysName", "sysLogo", "path"]),
  },

  created() {},
  methods: {
    ...mapMutations(["reset"]),
    // 返回首页
    handleGoHome() {
      this.$router.push("/catalogue");
    },
    // 用户下拉操作
    handleCheck(command) {
      switch (command) {
        case "editPwd":
          this.changePwdVisible = true;
          break;
        case "loginOut":
          this.$confirm("确定要退出登录吗?", "系统提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            cancelButtonClass: "btn-custom-cancel",
            type: "warning",
          })
            .then(() => {
              this.loginOut();
            })
            .catch(() => {
              console.log("取消成功！");
            });
          break;
      }
    },
    async loginOut() {
      let res = await loginOut();
      if (res.data.code == 1) {
        this.$message.success("退出成功，请重新登录");
        this.reset();
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 64px;
  width: 100%;
  padding: 0px 24px;
  // background-color: #2681e8;
  background: linear-gradient(90deg, #1845c6 0%, #2999ff 100%);
  color: #fff;
  flex-shrink: 0;
  margin-bottom: 20px;
  .left {
    height: 100%;
  }
  .title {
    max-width: 200px;
    font-size: 18px;
  }
  .back-btn {
    a {
      color: #fff;
      font-size: 14px;
    }
  }
}
/deep/.el-menu {
  height: 100% !important;
  border: none !important;
  background-color: transparent;
  i {
    color: rgba(254, 254, 254, 0.65);
  }
  .el-menu-item {
    height: 100% !important;
    border: none !important;
    display: flex;
    align-items: center;
    color: rgba(254, 254, 254, 0.65);

    &:hover {
      // background-color: transparent !important;
      color: #fff !important;
      background-color: #1890ff !important;
      // border-bottom: 2px solid #fff !important;
    }
  }
}
/deep/.el-submenu {
  height: 100% !important;
  .el-submenu__title {
    height: 100% !important;
    display: flex;
    align-items: center;
    color: rgba(254, 254, 254, 0.65) !important;
    border: none !important;
    &:hover {
      // background-color: transparent !important;
      color: #fff !important;
      background-color: #1890ff !important;
      // border-bottom: 2px solid #fff !important;
    }
  }
}
.el-menu--horizontal .el-menu .el-menu-item {
  color: #909399;
  &:hover {
    i {
      color: #1890ff;
    }
    color: #1890ff;
  }
}

.el-menu--horizontal .el-menu .el-menu-item.is-active {
  color: #1890ff !important;
}
/deep/.is-active {
  // border-bottom: 2px solid #fff !important;
  color: #fff !important;
  // background-color: transparent !important;
  background-color: #1890ff !important;
  .el-submenu__title {
    border: none !important;
    color: #fff !important;
    // background-color: transparent !important;
    background-color: #1890ff !important;
  }
}
</style>
