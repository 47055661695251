<template>
  <div class="app">
    <div class="loading" v-if="loading">
      <div>
        <div class="title">{{ title }}...</div>
        <el-progress :percentage="percentage" color="#1890ff"></el-progress>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      percentage: 1,
      loading: false,
      title: "文件导出中",
    };
  },
  computed: {},
  beforeCreate() {
    Vue.prototype.$app = this;
  },
  created() {},
};
</script>

<style lang="less" scoped>
.app {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99999;
    .title {
      color: #fff;
      font-size: 14px;
      text-align: center;
    }
    .el-progress {
      width: 300px;
      :deep(.el-progress__text) {
        color: #fff !important;
      }
    }
  }
}
</style>
