<template>
  <div class="y-table">
    <el-table
      ref="MyTable"
      v-loading="loading"
      :border="isBorder"
      :data="data"
      style="width: 100%"
      :size="size"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <el-table-column
        v-for="(v, i) in column"
        :key="i"
        :reserve-selection="v.reserveSelection"
        :prop="v.prop"
        :type="v.type"
        :index="v.index"
        :label="v.label"
        :width="v.width"
        :align="v.align"
        :fixed="v.fixed"
        :formatter="v.formatter"
        :min-width="v.minWidth"
        :show-overflow-tooltip="v.showOverflowTooltip"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "YTable",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    border: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    column: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  watch: {
    data: {
      handler() {
        this.$nextTick(() => {
          this.$refs.MyTable.bodyWrapper.scrollTop = 0;
          this.$refs.MyTable.bodyWrapper.scrollLeft = 0;
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    isBorder() {
      return !!this.border;
    },
  },
  methods: {
    clearSelection() {
      this.$refs.MyTable.clearSelection();
    },
  },
};
</script>

<style lang="less" scoped>
.y-table {
  width: 100%;
  height: 100%;
}
</style>
