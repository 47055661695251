<template>
  <div class="pagination" v-if="total">
    <el-pagination
      background
      :layout="layout"
      :pager-count="5"
      :total="total"
      :current-page="page"
      :page-size="Size"
      :page-sizes="pageSizes"
      v-bind="$attrs"
      @current-change="changePagination"
      @size-change="changeSize"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
      default: 0,
    },
    size: {
      required: true,
      type: Number,
      default: 10,
    },
    current: {
      required: true,
      type: Number,
      default: 1,
    },
    layout: {
      type: String,
      default: "total,prev,pager,next,sizes,jumper",
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 50, 100, 200],
    },
  },
  data() {
    return {};
  },
  computed: {
    Size: {
      get() {
        return this.size;
      },
      set(value) {
        this.$emit("update:size", value);
      },
    },
    page: {
      get() {
        return this.current;
      },
      set(value) {
        this.$emit("update:current", value);
      },
    },
  },
  methods: {
    changePagination(cur) {
      this.page = cur;
      if (this.$listeners.get) {
        this.$listeners.get();
      }
      this.$emit("currentChange", cur);
    },
    changeSize(size) {
      this.Size = size;
      if (this.$listeners.get) {
        this.$listeners.get();
      }
      this.$emit("sizeChange", size);
    },
  },
};
</script>

<style lang="less" scoped></style>
